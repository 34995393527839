.csv {
	&__link {
		cursor: pointer;
		&:hover {
			color: $navy-hover;
		}
	}
	&__export {
		color: $green;
	}
	&__error {
		color: $red;
	}
}
