.login {
	min-height: 100vh;
	position: relative;
	background-color: $blue-dark;
	@include flexCenter;
	flex-direction: column;
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0%;
		right: 0%;
		height: 100%;
		width: 100%;
	}

	&__bg_img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	&__screen {
		@include flexHorizontal;
		position: relative;
		margin: 1rem;
		border-radius: 0;
		box-shadow: 5px 8px 20px 8px #00000024;
	}

	&__content {
		width: 40rem;
		min-height: 50rem;
		padding: 3.6rem 5rem 4rem 5rem;
		border-radius: 0;
		background-color: $blue-darker;
	}

	&__link_box {
		text-align-last: right;
		margin-top: -0.6rem;
		margin-bottom: 2.8rem;
	}

	&__label,
	&__link {
		color: $white;
		font-weight: 600;
	}

	&__label {
		text-transform: uppercase;
		font-size: 1.1rem;
		opacity: 0.5;
	}

	&__link {
		&:link,
		&:visited {
			font-size: 1.2rem;
			color: $navy;
			text-transform: uppercase;
		}
	}
}
